import {
  Typography,
  Grid,
  CssBaseline,
  ThemeProvider,
  Box,
} from "@mui/material";
import { createTheme } from "./theme/create-theme";

function App() {
  return (
    <>
      <ThemeProvider
        theme={createTheme({
          direction: "ltr",
          responsiveFontSizes: true,
          mode: "light",
        })}
      >
        <CssBaseline />
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            height: "80vh",
          }}
        >
          <Grid
            item
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            direction={"column"}
            sx={{
              padding: "1rem 2rem 1rem 2rem",
              borderRadius: "20px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                fontFamily={"FH Oscar"}
                fontSize={"50px"}
                fontWeight={60}
                sx={{
                  "-webkit-user-select": "none",
                  "-moz-user-select": "none",
                  "-ms-user-select": "none",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  marginBottom: "-5px",
                }}
              >
                Lenterman
              </Typography>
              <Box
                border="1px solid"
                sx={{
                  opacity: 0.3,
                }}
              ></Box>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}

export default App;
