import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import "./fonts/FHOscar-Regular.otf";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// TODO: Re-Enable react strict mode for
root.render(<App />);
